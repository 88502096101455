body{
    background-color: #010d17;
    font-size: 0.9em;
}
.constrained-height {
    max-height: 300px; /* Set this to your desired maximum height */
    width: auto;
    height: auto; /* Maintain aspect ratio */
    display: block; /* Remove inline display */
    margin-left: auto; /* Center image horizontally */
    margin-right: auto; /* Center image horizontally */
}

li{
    cursor: pointer !important;
}

h2{
    color: #fb6535;
}

.accordion-button:not(.collapsed) {
    background-color: lightslategray !important; /* Light blue background for the header */
    color: #010d17; /* Change text color if needed */
}
.accordion-body {
    background-color: white !important; /* Very light blue background for the body */
}

.accordion-button.collapsed {
    background-color: lightgray !important; /* Light blue background for the header */
    color: #010d17; /* Change text color if needed */
}